import styled, { css } from "styled-components";

export const WrapperMain = styled.div`
  padding: 1rem;

  @media screen and (min-width: 480px) {
    padding: 2rem;
  }

  @media screen and (min-width: 960px) {
    padding: 3rem;
  }

  @media screen and (min-width: 1280px) {
    padding: 4rem;
  }
`;

export const Header = styled.header`
  padding-bottom: 1rem;
  border-bottom: 0.125rem solid white;
`;

export const Main = styled.main``;

export const Section = styled.section``;

export const Article = styled.article`
  padding-bottom: 2rem;
  border-bottom: 0.5rem solid #333;
`;

export const Wrapper = styled.div`
  max-width: 112rem;

  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
`;

export const Column1 = styled.div`
  @media screen and (min-width: 1280px) {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-column: 2 / 3;
  }
`;

export const Details1 = styled.div`
  @media screen and (min-width: 1280px) {
    grid-column: 1 / 2;
  }
`;

export const Details2 = styled.div`
  @media screen and (min-width: 1280px) {
    grid-column: 2 / 3;
  }
`;

export const Column2 = styled.div`
  grid-column: 1 / 2;
`;

export const ItemData = styled.div`
  display: block;
  grid-column: 1 / 3;
`;

export const ItemMedia = styled.div`
  margin-bottom: 2rem;

  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 0.5rem;
    margin-bottom: 0;
  }
`;

export const CTA = styled.a`
  display: block;
  padding: 0.5rem 1rem;
  background-color: white;
  color: #090909;
  font-family: var(--font-bold);
  text-decoration: none;
  margin: 2rem 0;
  transition: 250ms background-color, 250ms color;
  width: max-content;

  &:hover {
    background-color: #333;
    color: white;
  }
`;

export const Link = styled.a`
  color: white;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  transition: 250ms border-bottom;

  &:hover {
    border-bottom: 2px solid white;
  }
`;

export const Media = styled.div<{ $index: number; $size?: string }>(
  ({ $index, $size }) => `
  display: ${$index === 2 ? "none" : "block"};
  margin: 0 0 1rem;

  @media screen and (min-width: 800px) {
    display: block;
    ${$size === "large" && "grid-column: 1 / 3;"}
  }
`
);

export const Figure = styled.figure`
  padding: 0;
  margin: 0;
`;

const captionStyles = css`
  position: relative;
  display: block;
  color: #ccc;
  font-size: 1rem;
  padding: 0;
  margin: 0.5rem 0 0;
`;

export const FigureCaption = styled.figcaption`
  ${captionStyles}
`;

export const Caption = styled.p`
  ${captionStyles}
`;
