import styled from "styled-components";

export const Wrapper = styled.li`
  display: inline-block;
  margin: 0;
  padding: 0.25rem 0.5rem;
  border: 1px solid #333;
  color: #999;
  font-size: 0.9rem;
`;
