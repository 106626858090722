import styled from "styled-components";

export const Heading = styled.button`
  border: 0;
  outline: none;
  background: transparent;
  color: white;
  width: 100%;
  cursor: pointer;
  text-align: left;
`;

export const Body = styled.div`
  overflow: hidden;
  transition: 0.25s height;
`;

export const Inner = styled.div`
  padding-bottom: 2rem;
`;

export const Wrapper = styled.div<{ $height: number; $isOpen: boolean }>(
  ({ $height, $isOpen }) => `
    border-bottom: 0.125rem solid white;

    ${Body} {
      height: ${$isOpen ? `${$height}px` : 0};
    }
  `
);
