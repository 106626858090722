import { DependencyList, PropsWithChildren, useState } from "react";

import { useHeight } from "../../hooks";

import * as Styles from "./Collapse.styles";

export interface CollapseProps extends PropsWithChildren {
  dependencyList: DependencyList;
  title: string;
}

export function Collapse({ children, dependencyList, title }: CollapseProps) {
  const [isOpen, setOpen] = useState(false);

  const [ref, height] = useHeight(dependencyList);

  return (
    <Styles.Wrapper $height={height} $isOpen={isOpen}>
      <Styles.Heading onClick={() => setOpen((value) => !value)}>
        <h2>{title}</h2>
      </Styles.Heading>
      <Styles.Body>
        <Styles.Inner ref={ref}>{children}</Styles.Inner>
      </Styles.Body>
    </Styles.Wrapper>
  );
}
