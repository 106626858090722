import { Suspense, useState } from "react";
import "./App.css";
import * as Styles from "./App.styles";
import { Collapse, Tag, Tags } from "./components";
import data from "./data/work.json";

export function App() {
  const [mediaLoaded, setLoaded] = useState<boolean[]>(Array.from(Array(data.items.length)).map(() => false));

  return (
    <Styles.WrapperMain>
      <Styles.Header>
        <h1>Seb Sowter</h1>
        <p>
          Hi, I'm Seb, a creative developer building websites and games located on the southern border of London.
          <br />I have over two decades experience working in the media and technology industries crafting online and offline experiences. <br />
          If you'd like to discuss a project please get in touch via{" "}
          <Styles.Link href="https://www.linkedin.com/in/sebsowter/" target="_blank">
            LinkedIn
          </Styles.Link>{" "}
          or email me at <Styles.Link href="mailto:seb@sebsowter.com">seb@sebsowter.com</Styles.Link>.
        </p>
        <h3>Key skills</h3>
        <Tags>
          <Tag>JavaScript</Tag>
          <Tag>TypeScript</Tag>
          <Tag>HTML5</Tag>
          <Tag>CSS</Tag>
          <Tag>react.js</Tag>
          <Tag>svelte.js</Tag>
          <Tag>three.js</Tag>
        </Tags>
      </Styles.Header>
      <Styles.Main>
        <Styles.Section>
          {data.items.map((item, index) => {
            return (
              <Suspense fallback={null}>
                <Collapse dependencyList={[mediaLoaded]} key={index} title={item.title}>
                  <Styles.Wrapper>
                    <Styles.Column2>
                      {item.items && (
                        <Styles.ItemMedia>
                          {item.items.map((media, index) => {
                            if (media.type === "image") {
                              return (
                                <Styles.Media key={index} $index={index} $size={media.size}>
                                  <Styles.Figure>
                                    <img src={media.url} alt={item.title} />
                                    {media.caption && <Styles.FigureCaption>{media.caption}</Styles.FigureCaption>}
                                  </Styles.Figure>
                                </Styles.Media>
                              );
                            }

                            if (media.type === "video") {
                              return (
                                <Styles.Media key={index} $index={index} $size={media.size}>
                                  <video autoPlay loop muted playsInline onLoadedData={() => setLoaded((value) => value.map((_, i) => i === index))}>
                                    <source src={media.url} type="video/mp4" />
                                  </video>
                                  {media.caption && <Styles.Caption>{media.caption}</Styles.Caption>}
                                </Styles.Media>
                              );
                            }

                            return null;
                          })}
                        </Styles.ItemMedia>
                      )}
                    </Styles.Column2>
                    <Styles.Column1>
                      <Styles.Details1>
                        {item.description && (
                          <Styles.ItemData>
                            <p>{item.description}</p>
                          </Styles.ItemData>
                        )}
                        {item.cta && (
                          <Styles.CTA href={item.cta.url} target="_blank">
                            {item.cta.label}
                          </Styles.CTA>
                        )}
                      </Styles.Details1>
                      <Styles.Details2>
                        {item.client && (
                          <div className="item-data">
                            <h3>Client</h3>
                            <p>{item.client}</p>
                          </div>
                        )}
                        {item.agency && (
                          <div className="item-data">
                            <h3>Agency</h3>
                            <p>{item.agency}</p>
                          </div>
                        )}
                        {item.industry && (
                          <div className="item-data">
                            <h3>Industry</h3>
                            <p>{item.industry}</p>
                          </div>
                        )}
                        {item.type && (
                          <div className="item-data">
                            <h3>What I did</h3>
                            <p>{item.type}</p>
                          </div>
                        )}
                        {item.when && (
                          <div className="item-data">
                            <h3>When</h3>
                            <p>{item.when}</p>
                          </div>
                        )}
                        {item.status && (
                          <div className="item-data">
                            <h3>Status</h3>
                            <p>{item.status}</p>
                          </div>
                        )}
                        {item.technology && (
                          <div className="item-data">
                            <h3>Tech</h3>
                            <Tags>
                              {item.technology.map((tech, index) => {
                                return <Tag key={index}>{tech}</Tag>;
                              })}
                            </Tags>
                          </div>
                        )}
                      </Styles.Details2>
                    </Styles.Column1>
                  </Styles.Wrapper>
                </Collapse>
              </Suspense>
            );
          })}
        </Styles.Section>
      </Styles.Main>
    </Styles.WrapperMain>
  );
}
