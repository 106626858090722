import { DependencyList, useEffect, useRef, useState } from "react";

export const useHeight = (dependencyList: DependencyList = []): [React.RefObject<HTMLDivElement>, number] => {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);

  useEffect(() => {
    const onResize = () => {
      if (ref.current?.offsetHeight) {
        setHeight(ref.current.offsetHeight);
      }
    };

    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [dependencyList]);

  return [ref, height];
};
